<template>
    <a href="#0" class="o-scroller" v-scroll-to="target">
        <span class="o-scroller__icon"></span>
        <span class="o-scroller__text">Scroll</span>
    </a>
</template>

<script>
    export default {
        name: 'Scroller',
        props: {
            target: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../assets/scss/_essentials.scss";

    @keyframes scroller {
        0% {
            top: rem(8);
        }
        50% {
            top: rem(25)
        }
        100% {
            top: rem(8);
        }
    }

    .o-scroller {
        @include size(rem(50), rem(80));
        display: block;
        font-size: rem(14);
        position: relative;
        left: rem(-23);
        text-align: center;
        text-transform: uppercase;
        margin-top: rem(30);

        @include media-breakpoint-down(xsl) {
            display: none;
        }

        @include hover-focus {
            color: $white;
        }

        &:after {
            @include absolute(top 0 left 50%);
            @include size(rem(1), rem(50));
            background: $white;
            border-radius: rem(10);
            content: '';
        }

        &__icon {
            @include absolute(top rem(8) left rem(15));
            @include square(rem(20));
            animation: scroller 2s infinite;
            border: rem(1) solid $white;
            border-radius: 50%;
            transition: top $transition-duration $transition-timing-base;
            transform-origin: center center;

            &:before,
            &:after {
                @include absolute(top rem(6) left rem(9));
                @include size(rem(1), rem(5));
                background: $white;
                border-radius: rem(10);
                content: '';
                transform-origin: bottom right;
                transform: rotate(-40deg);
            }

            &:after {
                transform: rotate(40deg);
            }
        }

        &__text {
            @include absolute(bottom 0 left 50%);
            color: $white;
            transform: translateX(-50%);
            white-space: nowrap;
        }
    }
</style>
