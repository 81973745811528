<template>
    <transition-group name="fade" class="c-detail" tag="section">
        <template v-if="!loading">
            <section key="interior-intro" class="c-detail-hero c-detail-hero--intro" v-if="interior.image">
                <observed-image class-name="c-detail-hero__img u-cover" :src="interior.image"/>

                <div class="c-detail-hero__content">
                    <h1>{{ interior.name }}</h1>

                    <scroller target=".c-detail-banner"/>
                </div>
            </section>

            <section key="interior-banner" class="c-detail-banner">
                <div class="c-detail-banner__content">
                    <template v-if="interior.descriptions[0]">
                        <h4>{{ interior.descriptions[0].header }}</h4>
                        <p>{{ interior.descriptions[0].description }}</p>
                    </template>

                    <template v-else-if="interior.descriptions.length === 0">
                        <inline-alert type="warning">Geen teksten gevonden voor {{ interior.name }}</inline-alert>
                    </template>

                    <template v-else>
                        <inline-alert type="error">Er ging iets fout met het laden van de details</inline-alert>
                    </template>
                </div>

                <observed-image class-name="c-detail-banner__img u-cover" v-if="interior.images.sfeer" :src="interior.images.sfeer[0].path"/>
                <div class="c-detail-banner__img u-cover" v-else style="display: flex; align-items: center; justify-content: center;">
                    <inline-alert type="warning">Geen sfeer afbeelding gevonden voor {{ interior.name }}</inline-alert>
                </div>
            </section>

            <section key="interior-info" class="c-detail-info" v-if="interior.descriptions.length > 0">
                <div class="c-detail-info__list">
                    <div class="c-detail-text" v-for="desc in interior.descriptions" :key="desc.id">
                        <h4>{{ desc.header }}</h4>
                        <p v-html="desc.description"></p>
                    </div>
                </div>
            </section>

            <section key="interior-showcase-1" class="c-summary-showcase-1" v-if="interior.images.other.length === 1">
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[0].path + ')' }"></div>
            </section>

            <section key="interior-showcase-2" class="c-summary-showcase-2" v-if="interior.images.other.length === 2">
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[0].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[1].path + ')' }"></div>
            </section>

            <section key="interior-showcase-3" class="c-summary-showcase-3" v-if="interior.images.other.length === 3">
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[0].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[1].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[2].path + ')' }"></div>
            </section>

            <section key="interior-showcase-4" class="c-summary-showcase-4" v-if="interior.images.other.length === 4">
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[0].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[1].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[2].path + ')' }"></div>
                <div class="c-summary-showcase__img u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[3].path + ')' }"></div>
            </section>

            <section key="interior-showcase-5" class="c-summary-showcase" v-if="interior.images.other.length === 5">
                <div class="c-summary-showcase__img c-summary-showcase__img--big u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[0].path + ')' }"></div>
                <div class="c-summary-showcase__img c-summary-showcase__img--small-1 u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[1].path + ')' }"></div>
                <div class="c-summary-showcase__img c-summary-showcase__img--medium-1 u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[2].path + ')' }"></div>
                <div class="c-summary-showcase__img c-summary-showcase__img--medium-2 u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[3].path + ')' }"></div>
                <div class="c-summary-showcase__img c-summary-showcase__img--small-2 u-cover" :style="{ backgroundImage: 'url(' + interior.images.other[4].path + ')' }"></div>
            </section>

            <footer key="interior-footer" class="c-detail-footer">
                <router-link to="/interior" class="o-c2a-link o-c2a-link--tint o-c2a-link--reverse">
                    <span class="o-c2a-link__text">Terug naar configuratie</span>
                    <span class="o-c2a-link__icon">
                        <span class="o-c2a-link__icon-circle"></span>
                    </span>
                </router-link>
            </footer>

        </template>
    </transition-group>
</template>

<script>
    import InlineAlert from '../../components/InlineAlert.vue';
    import ObservedImage from '../../components/ObservedImage.vue';
    import Scroller from '../../components/Scroller.vue';
    import { detailsService } from '../../services';

    export default {
        components: { Scroller, InlineAlert, ObservedImage },
        data () {
            return {
                loading: true,
                interior: {},
            };
        },
        created () {
            this.getDetails();
        },
        methods: {
            getDetails () {
                // get style details
                if (this.$route.params.id)
                {
                    detailsService
                        .getById('interiors', this.$route.params.id)
                        .then((result) => {
                            this.loading = false;
                            this.interior = result;

                            console.log(this.interior);
                        }).catch(() => {
                            this.loading = false;
                        });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/essentials";

    .c-detail-hero {
        display: grid;
        grid-template-columns: 7.5% 1fr 2fr 7.5%;
        grid-template-rows: 1fr 45vh;
        height: calc(100vh - #{rem(map_get($header-height, xs))});
        position: relative;

        @include media-breakpoint-up(sm) {
            height: calc(100vh - #{rem(map_get($header-height, sm))} - #{rem(map_get($footer-height, sm))});
            grid-template-rows: 1fr 33vh;
        }

        @include media-breakpoint-between(md, lg) {
            grid-template-rows: 1fr 45vh;
        }

        @include media-breakpoint-up(xl) {
            grid-template-rows: 1fr rem(400);
        }

        &:before {
            @include fill;
            @include gradient-y(rgba(black, .0), rgba(black, .58));
            content: "";
            display: block;
            z-index: 0;
        }

        &__img {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
        }

        &__content {
            grid-row: 2;
            grid-column: 2;
            color: $white;
            z-index: 1;

            h1,
            .alpha {
                color: inherit;
                text-transform: none;
            }
        }
    }

    .c-detail-banner {
        background-color: $gray-light;
        display: grid;
        grid-template-rows: 1fr 1fr;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1.5fr 1fr;
            grid-template-rows: auto;
        }

        &__img {
            grid-row: 1;
        }

        &__content {
            background-color: $gray-lighter;
            grid-row: 2;
            padding: rem(50) 10%;

            *:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                grid-row: 1;
                padding: 15%;
            }
        }
    }

    .c-detail-info {
        background-color: $white;
        display: grid;
        grid-template-columns: 10% 1fr 10%;
        padding: rem(50) 0;

        @include media-breakpoint-up(sm) {
            padding: 7.5% 0;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 20% 1fr 20%;
        }

        &__list {
            display: grid;
            grid-gap: rem(50);
            grid-column: 2;

            @include media-breakpoint-up(sm) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .c-summary-showcase {
        border: rem(5) solid $white;
        display: grid;
        grid-gap: rem(5);
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-template-areas: "showcase-big showcase-big showcase-small-1 showcase-medium-1"
                             "showcase-big showcase-big showcase-medium-2 showcase-medium-1"
                             "showcase-big showcase-big showcase-medium-2 showcase-small-2";
        height: calc(100vh - #{rem(80)} - #{rem(120)});

        &__img {
            &--big {
                grid-area: showcase-big;
            }

            &--medium-1 {
                grid-area: showcase-medium-1;
            }

            &--medium-2 {
                grid-area: showcase-medium-2;
            }

            &--small-1 {
                grid-area: showcase-small-1;
            }

            &--small-2 {
                grid-area: showcase-small-2;
            }
        }
    }

    .c-summary-showcase-2 {
        border: rem(5) solid $white;
        display: grid;
        grid-gap: rem(5);
        grid-template-columns: repeat(2, 1fr);
        height: calc(100vh - #{rem(80)} - #{rem(120)});
    }

    .c-summary-showcase-3 {
        border: rem(5) solid $white;
        display: grid;
        grid-gap: rem(5);
        grid-template-columns: repeat(3, 1fr);
        height: calc(100vh - #{rem(80)} - #{rem(120)});
    }

    .c-summary-showcase-4 {
        border: rem(5) solid $white;
        display: grid;
        grid-gap: rem(5);
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        height: calc(100vh - #{rem(80)} - #{rem(120)});
    }

    .c-detail-footer {
        @include fixed(left 0 right 0 bottom 0);
        display: grid;
        grid-gap: rem(7);
        grid-template-columns: 1fr;
        border: rem(7) solid $white;
        z-index: 200;
    }
</style>
